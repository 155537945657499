.header {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  background-color: #fff;
  border-bottom: 2px solid #323b4449;
  display: flex;
  justify-content: center;
  z-index: 10;
  transition: .5s ease-in-out;
}

.header__container {
  padding: 25px 140px;
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  /* width: 80px; */
  height: 80px;
  aspect-ratio: 1 / 1;

}

@media screen and (max-width: 1280px) {
  .header__container {
    width: calc(100% - 200px);
    padding: 25px 100px;
  }
}

@media screen and (max-width: 750px) {
  .header__container {
    width: calc(100% - 100px);
    padding: 25px 50px;
  }
}

@media screen and (max-width: 550px) {
  .header {
    height: 50px;
  }

  .header__container {
    width: calc(100% - 50px);
    padding: 0 25px;
  }

  .header__logo {
    height: 100%;
  }
}

@media screen and (max-width: 420px) {
  .header {
    height: 40px;
  }

  .header__container {
    width: calc(100% - 20px);
    padding: 0 10px;
  }

  /* .header__logo {
    height: 300%;
  
  } */
}