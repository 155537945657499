html {
  scroll-behavior: smooth;
}

/* .page {
  width: 100%; */
  /* background-color: #FBF6F0; */
/* } */

.app {
  display: flex;
  flex-direction: column;
  /* max-width: 1280px; */
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  /* background-color: #FBF6F0; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-family: 'Inter', Arial, sans-serif;
}