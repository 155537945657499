.contacts {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.contacts__container {
  padding: 100px 140px 200px;
  width: 1000px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.contacts__title { 
  font-size: 48px;
}

.contacts__description {
  text-align: center;
  width: 500px;
  margin: 15px 0;
  font-size: 16px;
}

.contacts__item {
  font-size: 18px;
  font-weight: 500;
  background-color:#a483de50;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .contacts__container {
    width: calc(100% - 200px);
    padding: 100px 100px 175px;
  }

  .contacts__title {
    font-size: 40px;
  }
}

@media screen and (max-width: 600px) {
  .contacts__container {
    width: calc(100% - 150px);
    padding: 80px 75px 150px;
  }

  .contacts__title {
    font-size: 35px;
  }

  .contacts__description {
    width: 100%;
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .contacts__container {
    width: calc(100% - 100px);
    padding: 50px 50px 150px;
  }

  .contacts__title {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) {
  .contacts__container {
    width: calc(100% - 100px);
    padding: 50px 50px 150px;
  }

  .contacts__title {
    font-size: 20px;
  }

  .contacts__description {
    /* width: 100%; */
    font-size: 13px;
  }

  .contacts__item {
    font-size: 13px;
    padding: 8px;
  }
}