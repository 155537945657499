.portfolio {
  width: 100%;
  height: auto;
  /* background-color: #ffffff; */
  background: linear-gradient(#cda0f7,#fff);

  display: flex;
  justify-content: center;
}

.portfolio__container {
  width: 1000px;
  padding: 110px 140px;
}

.portfolio__title {
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 60px;
}

.portfolio__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 30px;
  grid-column-gap: 20px;
}

@media screen and (max-width: 1000px) {
  .portfolio__container {
    width: calc(100% - 150px);
    padding: 100px 75px 100px;
  }

  .portfolio__title {
    font-size: 35px;
  }
}

@media screen and (max-width: 800px) {
  .portfolio__container {
    width: calc(100% - 100px);
    padding: 100px 50px 100px;
  }

  .portfolio__title {
    font-size: 35px;
  }
}

@media screen and (max-width: 550px) {
  .portfolio__container {
    /* width: calc(100% - 70px); */
    padding: 70px 50px 100px;
  }

  .portfolio__title {
    font-size: 25px;
  }

  .portfolio__grid {
    align-items: center;
    /* width: 90%; */
    grid-template-columns: repeat(1, 1fr);
    /* grid-row-gap: 30px; */
    grid-column-gap: 20px;
  }
}