.about-us {
  width: 100%;
  height: auto;
  background-color: #BBDEFB;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#fff, #a583dec4);
}

.about-us__container {
  width: 1000px;
  padding: 120px 140px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.about-us__main {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}

.about-us__major {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 125px;
}

.about-us__svg {
  width: 350px;
  position: absolute;
  top: -20px;
  left: -50px;
  z-index: 0;
}

.about-us__img {
  background-image: url(../../../images/logo_2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 500px;
  width: 250px;
  aspect-ratio: 1 / 1;
  z-index: 1;
}

.about-us__name {
  font-size: 40px;
  text-align: center;
  margin-top: 20px;
  z-index: 1;
}

.about-us__text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 450px;
  margin-right: 30px;
  z-index: 2;
}

.about-us__title {
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 20px;
}

.about-us__description {
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  text-align: justify;
  hyphens: auto;
}

.about-us__gradient {
  height: 15px;
  width: 100%;
  background: linear-gradient(#fff, #cda0f7a3);
}

.about-us__cards {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.about-us__card{
  width: 390px;
  height: auto;
  background-color: #f5faffc1;
  border-radius: 10px;
  padding: 30px;
}

.about-us__card-title {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}

.about-us__card-description{ 
  font-size: 17px;
  font-weight: 400;
  text-align: justify;
  line-height: 25px;
  hyphens: auto;
}

@media screen and (max-width: 1280px) {
  .about-us__container {
    width: calc(100% - 200px);
    padding: 120px 100px 50px;
  }
}

@media screen and (max-width: 1120px) {
  .about-us__container {
    width: calc(100% - 150px);
    padding: 120px 75px 50px;
  }

  .about-us__major { 
    margin-left: 25px;
  }

  .about-us__text { 
    margin-right: 0px;
  }

  .about-us__cards {
    flex-direction: column;
    align-items: center;
  }

  .about-us__card {
    margin-bottom: 50px;
  }
  
  .about-us__card:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 920px) {
  .about-us__container {
    width: calc(100% - 150px);
    padding: 120px 75px 50px;
  }

  .about-us__major { 
    margin-left: 0;
    width: 45%;
  }

  .about-us__text {
    width: 45%;
    margin-right: 0px;
  }

  .about-us__name {
    margin-top: 25px;
  }

  .about-us__img{
    width: 100%;
  }

  .about-us__svg {
    width: 100%;
    top: 0;
    left: 0;
  }

  .about-us__cards {
    margin-top: 40px;
  }

  .about-us__card {
    margin-bottom: 30px;
  }

  .about-us__card-title {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .about-us__card-description {
    font-size: 15px;
  }
}

@media screen and (max-width: 800px) {
  .about-us__major { 
    width: 47%;
  }

  .about-us__text {
    width: 47%;
  }

  .about-us__img {
    background-size: 150%;
  }

  .about-us__title {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .about-us__description{
    font-size: 15px;
    line-height: 23px;
  }
}

@media screen and (max-width: 600px) {
  .about-us__container {
    width: calc(100% - 80px);
    padding: 100px 40px 50px;
  }

  .about-us__name {
    margin-top: 15px;
    font-size: 25px;
  }

  .about-us__major {
    width: 28%;
  }

  .about-us__img {
    margin-top: 0;
  }

  .about-us__text {
    width: 68%;
  }

  .about-us__title {
    font-size: 25px;
  }

  .about-us__description {
    font-size: 13px;
    line-height: 17px;
  }

  .about-us__cards {
    margin-top: 40px;
  }

  .about-us__card {
    width: calc(90% - 40px);
    padding: 20px;
    margin-bottom: 30px;
  }

  .about-us__card-title {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .about-us__card-description {
    font-size: 13px;
    line-height: 20px;
  }
}

@media screen and (max-width: 420px) {
  .about-us__container {
    width: calc(100% - 20px);
    padding: 80px 10px 50px;
  }

  .about-us__main {
    flex-direction: column;
    align-items: center;
  }

  .about-us__major {
    width: 70%;
    margin-bottom: 15vw;
  }

  .about-us__name {
    font-size: 8vw;
  }

  .about-us__text {
    width: 80%;
  }

  .about-us__title {
    font-size: 6vw;
  }

  .about-us__description {
    font-size: 4vw;
    line-height: 5vw;
  }
}


@media screen and (max-width: 320px) {
  .about-us__card-title {
    font-size: 15px;
  }

  .about-us__card-description {
    font-size: 10px;
    line-height: 15px;
  }
}