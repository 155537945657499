.footer {
  /* outline: 1px red solid; */
  width: 100%;
  height: auto;
  background-color: black;
  color: #fff;
  display: flex;
  justify-content: center;
}

.footer__container {
  padding: 50px 140px;
  width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.footer__title {
  font-size: 20px;
}

.footer__links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100px;
  height: auto;
}

.footer__link {
  width: 35px;
  /* height: 25px; */
  /* outline: 1px red solid; */
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 1000px) {
  .footer__container {
    width: calc(100% - 100px);
    padding: 35px 50px;
  }
}

@media screen and (max-width: 450px) {
  .footer__container {
    width: calc(100% - 50px);
    padding: 25px 25px;
  }

  .footer__title {
    font-size: 13px;
  }

  .footer__links{
    width: 50px;
  }

  .footer__link {
    width: 20px;
  }
}