.preview {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  padding: 100px 0;
}

.preview__container {
  padding: 100px 0 0;
  width: 1280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* background-image: url('https://framer.com/m/Squiggle-b4lE.js@tMlbnN8wmzCbT2bqWmMJ'); */
  /* background-size: cover; */
  position: relative;
}

.preview__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
  z-index: 0;
}

/* .preview__box {
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 300px;
} */

.preview__image {
  /* position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:300px;
  height:300px;
  object-fit:cover;
  object-position: center; */
  height: 60%;
  width: 50%;
  object-position: center;
  object-fit: cover;
  z-index: 1;
  margin: 15px 0;
}

.preview__title {
  color:#000000;
  font-size: 80px;
  z-index: 1;
}

/* .preview__image {
  background-image: url('https://framer.com/m/Squiggle-b4lE.js@tMlbnN8wmzCbT2bqWmMJ');
  width: 500px;
  height: 600px;
  background-size: cover;
  background-position: center;
} */