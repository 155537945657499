.benefit {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.benefit__container {
  padding: 110px 140px 200px;
  width: 1000px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.benefit__title {
  width: 100%;
  text-align: left;
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 25px;
}

.benefit__item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: calc(100% - 40px);
  margin-top: 50px;
  background-color: #f3f9ffc1;
  border-radius: 25px;
  height: auto;
  padding: 20px;
  align-items: center;
  box-shadow: 5px 10px 20px 0px rgba(20, 46, 84, 0.31);
}

.benefit__item:first-child {
  margin-top: 0;
}

.benefit__img {
  width: 150px;
  aspect-ratio: 1 / 1;
  /* height: 150px; */
  /* margin-right: 70px; */
}

.benefit__text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60%;
}

.benefit__subtitle {
  text-align: left;
  margin-bottom: 20px;
  font-size: 23px;
  font-weight: 500;
}

.benefit__description {
  font-weight: 400;
  line-height: 20px;
}

@media screen and (max-width: 1280px) {
  .benefit__container {
    width: calc(100% - 200px);
    padding: 110px 100px 200px;
  }
}

@media screen and (max-width: 800px) {
  .benefit__container {
    width: calc(100% - 150px);
    padding: 85px 75px 200px;
  }

  .benefit__title {
    font-size: 25px;
    margin: 0;
  }

  .benefit__item {
    margin-top: 35px;
    width: calc(100% - 30px);
    padding: 20px 15px;
  }

  .benefit__img {
    width: 100px;
  }

  .benefit__text {
    width: 70%;
  }

  .benefit__subtitle {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .benefit__description {
    font-size: 15px;
    /* text-align: justify; */
  }
}

@media screen and (max-width: 600px) {
  .benefit__container {
    width: calc(100% - 100px);
    padding: 50px 50px 100px;
  }

  .benefit__title {
    font-size: 25px;
    margin: 0;
  }

  .benefit__item {
    margin-top: 35px;
    width: calc(100% - 20px);
    padding: 20px 10px;
  }

  /* .benefit__img {
    width: 100px;
  } */

  .benefit__subtitle {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .benefit__description {
    font-size: 13px;
    line-height: 18px;
  }
}

@media screen and (max-width: 450px) {
  .benefit__container {
    width: calc(100% - 40px);
    padding: 50px 20px 50px;
  }

  .benefit__title {
    font-size: 20px;
    margin: 0;
  }

  .benefit__item {
    flex-direction: column;
    /* margin-top: 35px; */
    width: calc(100% - 30px);
    padding: 20px 15px;
  }

  .benefit__img {
    /* width: 100px; */
    margin-bottom: 25px;
  }

  .benefit__text {
    width: 100%;
  }

  .benefit__subtitle {
    text-align: center;
    width: 100%;
    font-size: 17px;
    margin-bottom: 20px;
  }

  .benefit__description {
    font-size: 12px;
    line-height: 18px;
  }
}