.card {
  width: 1fr;
  height: 350px;
  border-radius: 15px;
  /* overflow: hidden; */
  opacity: 1;
  position: relative;
}

.card__image {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 15px;
}

.card__info {
  position: absolute;
  background-color: black;
  opacity: 0;
  width: calc(90% - 40px);
  height: auto;
  top: 100%;
  left: 20px;
  text-align: center;
  border-radius: 0 0 10px 10px;
  padding: 20px;
}

.card__title {
  color: #fff;
  text-align: left;
  font-size: 17px;
  margin-bottom: 10px;
}

.card__description {
  color: #fff;
  text-align: left;
  font-size: 15px;
  line-height: 22px;
  /* word-break: initial; */
}

.card:hover {
  /* padding: 0 5px 5px 0; */
  opacity: 0.9;
  transform: translateY(-10px);
  box-shadow: 5px 10px 20px 0px rgba(20, 46, 84, 0.31);
  z-index: 1;
}

.card:hover .card__info{
  opacity: 1;
  z-index: 2;
}

@media screen and (max-width: 1000px) {
  .card {
    height: 250px;
  }

  .card__title {
    line-height: 20px;
    font-size: 15px;
  }

  .card__description {
    font-size: 13px;
  }
}

@media screen and (max-width: 800px) {
  .card {
    height: 225px;
  }

  .card__info {
    left: 2vw;
  }
}

@media screen and (max-width: 550px) {
  .card {
    height: 325px;
  }

  .card__info {
    left: 4vw;
  }

  .card__title {
    font-size: 14px;
  }

  .card__description {
    font-size: 11px;
    line-height: 17px;
  }
}

@media screen and (max-width: 450px) {
  .card {
    height: 250px;
  }
}

@media screen and (max-width: 350px) {
  .card {
    height: 150px;
  }

  .card__info {
    padding: 15px 10px;
    width: calc(90% - 20px);
  }

  .card__title {
    font-size: 13px;
    line-height: 17px;
  }

  .card__description {
    font-size: 11px;
    line-height: 17px;
  }
}