* {
  margin: 0;
  padding: 0;
  transition: .3s ease;
}

.link {
  transition: .3s;
}

.link:hover {
  opacity: 0.7;
  cursor: pointer;
}