.nav-tab {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
  z-index: 1;
}

.nav-tab__link {
  margin: auto 0;
  text-decoration: none;
  color: #323b4492;
  font-size: 16px;
  font-weight: 500;
}

.nav-tab__link_active {
  color: #323b44;
}

@media screen and (max-width: 750px) {
  .nav-tab {
    width: 80%;
  }

  .nav-tab__link {
    font-size: 12px;
  }
}

@media screen and (max-width: 550px) {
  .nav-tab {
    width: 80%;
  }

  .nav-tab__link {
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  .nav-tab {
    width: 90%;
  }

  .nav-tab__link {
    font-size: 9px;
  }
}

@media screen and (max-width: 320px) {
  .nav-tab__link {
    font-size: 8px;
  }
}

@media screen and (max-width: 280px) {
  .nav-tab__link {
    font-size: 7px;
  }
}