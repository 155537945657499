@font-face {
  font-family: 'Inter';
  font-size: normal;
  font-weight: 900;
  src:
    url(./Inter-Black.woff2) format('woff2'),
    url(./Inter-Black.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-size: normal;
  font-weight: 500;
  src:
    url(./Inter-Medium.woff2) format('woff2'),
    url(./Inter-Medium.woff) format('woff');
}


@font-face {
  font-family: 'Inter';
  font-size: normal;
  font-weight: 400;
  src:
    url(./Inter-Regular.woff2) format('woff2'),
    url(./Inter-Regular.woff) format('woff');
}
