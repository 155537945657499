.materials {
  width: 100%;
  height: auto;
  background-color: #BBDEFB;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#a583dec4, #fff);
}

.materials__container {
  width: 1000px;
  padding: 160px 140px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.about-us__fila {
  background-color: #fff;
  border-radius: 10px;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 5px 5px 10px 0px rgba(20, 46, 84, 0.31);
}

.about-us__fila-title {
  position: absolute;
  font-size: 35px;
  font-weight: 500;
  top: -70px;
  left: 0px;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px 10px 0 0;
}

.about-us__fila-description {
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  text-align: justify;
  margin-bottom: 50px;
  line-height: 25px;
}

.about-us__flex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.about-us__foto {
  height: 170px;
  width: 170px;
  background-image: none;
  /* background-image: url(../../../images/photo_1_2024-05-18_23-50-53.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 170px;
  border-radius: 10px;
}

@media screen and (max-width: 1280px) {
  .materials__container {
    width: calc(100% - 150px);
    padding: 160px 75px;
  }

  .about-us__fila {
    width: calc(100% - 60px);
    padding: 50px 30px;
  }

  .about-us__foto {
    width: 13.28vw;
    height: 13.28vw;
    background-size: cover;
  }
}

@media screen and (max-width: 800px) {
  .materials__container {
    width: calc(100% - 150px);
    padding: 100px 75px;
  }

  .about-us__fila {
    width: calc(100% - 40px);
    padding: 30px 20px;
  }
  
  .about-us__fila-title {
    top: -50px;
    font-size: 25px;
  }

  .about-us__fila-description {
    font-size: 15px;
    margin-bottom: 20px;
    line-height: 20px;
  }
}

@media screen and (max-width: 600px) {
  .materials__container {
    width: calc(100% - 100px);
    padding: 70px 50px;
  }

  .about-us__fila {
    width: calc(100% - 40px);
    padding: 20px 20px;
  }
  
  .about-us__fila-title {
    top: -40px;
    font-size: 20px;
    padding: 15px 20px;
  }

  .about-us__fila-description {
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 18px;
  }
}

@media screen and (max-width: 400px) {
  .materials__container {
    width: calc(100% - 40px);
    padding: 70px 20px;
  }

  /* .about-us__fila {
    width: calc(100% - 30px);
    padding: 20px 20px;
  } */
  
  .about-us__fila-title {
    top: -40px;
    font-size: 20px;
    padding: 15px 20px;
  }

  .about-us__fila-description {
    font-size: 12px;
    margin-bottom: 20px;
    line-height: 17px;
  }
}